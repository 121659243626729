import React from "react";
import App from "./App";
import ReactDOM from "react-dom";
import { MintContextProvider } from "./store/mint-context";
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <MintContextProvider>
      <App />
    </MintContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
