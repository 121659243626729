const EnvConfigService =
  process.env.REACT_APP_CLOUDFLARE_PREVIEW &&
  process.env.REACT_APP_CLOUDFLARE_PREVIEW === "true"
    ? window.env
    : {
        presale_start_time: process.env.REACT_APP_PRESALE_START_TIME,
        public_start_time: process.env.REACT_APP_PUBLIC_START_TIME,
        expected_chain_id: process.env.REACT_APP_EXPECTED_CHAIN_ID,
        expected_chain_name: process.env.REACT_APP_EXPECTED_CHAIN_NAME,
        contract_address: process.env.REACT_APP_CONTRACT_ADDRESS,
        merkle_endpoint: process.env.REACT_APP_MERKLE_ENDPOINT,
        ether_scan_link: process.env.REACT_APP_ETHER_SCAN_LINK,
        fetch_data_interval_ms: process.env.REACT_APP_FETCH_DATA_INTERVAL_MS,
      };

export default EnvConfigService;
