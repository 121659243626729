import React from "react";

function KYWCardDescription(props) {
  return (
    <div>
      <div
        className="fusion-text fusion-text-3 fusion-text-no-margin"
        style={{ marginBottom: "20px" }}
      >
        <p>
          <strong>From Lucky Trader and the team that brought you Know Your Horses...</strong>
        </p>
      </div>
      <div className="fusion-text fusion-text-4" style={{ color: "#ffffff" }}>
      <p>We bring you the Know Your Wallet Founder's Pass NFT. </p>
      <p>
      <strong>This pass grants you:</strong>
      </p>
        <ul className="image-bullet" style={{ marginTop: "-15px" }}>
        <li style={{ textAlign: "left" }}>Lifetime access to our new website knowyourwallet.io and any future play-to-earn tools</li>
          <li style={{ textAlign: "left" }}>Game-specific Pro Tool access pass airdrops (including ZED Run)</li>
          <li style={{ textAlign: "left" }}>
          Private discord community
          </li>
          <li style={{ textAlign: "left" }}>
          Giveaways and more exciting things to come
          </li>
        </ul>
      </div>
    </div>
  );
}

export default KYWCardDescription;
