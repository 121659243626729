import React from "react";
import { useEffect, useContext, useState } from "react";
import {
  isOnMintListButPresaleNotStarted,
  isNotOnMintListAndMintListIsSet,
} from "./utils/SaleStateUtils";
import { toShortedHexString } from "./utils/Utils";

import {
  Nav,
  Navbar,
  Container,
  Row,
  Alert,
  Spinner,
} from "react-bootstrap";
import kywLogo from "./images/kyw-logo.png";
import kywCard from "./images/kyw-vid.gif";
import MintContext from "./store/mint-context";
import { MintingClient } from "./clients/MintingClient";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ConnectWallet from "./components/ConnectWallet";
import Tile from "./components/Tile";
import KYWCardDescription from "./components/KYWCardDescription";
import MetaMaskOnboarding from "@metamask/onboarding";
import SaleInfoDisplay from "./components/SaleInfoDisplay";
import TwitterIcon from "./components/TwitterIcon";
import DiscordIcon from "./components/DiscordIcon";
import EnvConfig from "./services/EnvConfig";

export function App() {
  const mintContext = useContext(MintContext);
  const mintingClient = new MintingClient();
  const [loading, setLoading] = useState(true);
  const [showOnMintListAlert, setShowOnMintListAlert] = useState(true);
  const [showNotOnMintListAlert, setShowNotOnMintListAlert] = useState(true);
  const checkIfWalletIsConnected = async () => {
    const accounts = await window.ethereum.request({ method: "eth_accounts" });
    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account:", account);
      mintContext.setCurrentAccount(account);
      return true;
    } else {
      console.log("No authorized account found app");
      return false;
    }
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  async function initAppData(isMetamaskInstalled) {
    try {
      setLoading(true);
      console.log(
        `Contract address is set as ${EnvConfig.contract_address}`
      );
      if (isMetamaskInstalled) {
        window.ethereum.on("chainChanged", handleChainChanged);
        getChainId();
        // await delay(2000);
        const isWalletConnected = await checkIfWalletIsConnected();

        let context = await mintingClient.getMintContext(isWalletConnected, mintContext.currentAccount);
        mintContext.setMintContext(context);
      }
    } finally {
      setLoading(false);
    }
  }

  async function getMintContext() {
    let context = await mintingClient.getMintContext(
      mintContext.isWalletConnected(), mintContext.currentAccount
    );
    mintContext.setMintContext(context);
  }

  async function getChainId() {
    let hexChainId = await window.ethereum.request({ method: "eth_chainId" });
    let chainId = toDecimal(hexChainId);
    mintContext.setChainId(chainId);
  }

  useEffect(() => {
    const isMetamaskInstalled = MetaMaskOnboarding.isMetaMaskInstalled()
    mintContext.setIsMetamaskInstalled(
      isMetamaskInstalled  
    );
    initAppData(isMetamaskInstalled);

    const interval = setInterval(() => {
      getMintContext();
    }, EnvConfig.fetch_data_interval_ms);
    return () => {
      console.log(`cleaning up`);
      clearInterval(interval);
      if (mintContext.isMetamaskInstalled) {
        window.ethereum.removeListener("chainChanged", handleChainChanged);
      }
    };
  }, [mintContext.currentAccount]);


  

  function handleChainChanged(_chainId) {
    console.log("on chain changed");
    window.location.reload();
  }

  function toDecimal(hexString) {
    return parseInt(hexString, 16);
  }

  function shouldShowIsOnMintListButPresaleNotStartedAlert() {
    if (showOnMintListAlert === false) {
      return false;
    }
    return isOnMintListButPresaleNotStarted(mintContext);
  }

  function shouldShowIsNotOnMintListAndMintListIsSetAlert() {
    if (showNotOnMintListAlert === false) {
      return false;
    }
    return isNotOnMintListAndMintListIsSet(mintContext);
  }


  if (loading) {
    return (
      <div
        id="loading"
        style={{ height: "100vh" }}
        className="loading d-flex align-items-center justify-content-center"
      >
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <Container>
      <Alert
        key="invalid-chain-alert"
        show={mintContext.isInvalidChain()}
        variant="dark"
        className="sticky-top"
      >
        Your wallet is connected to a different network than the smart contract.{" "}
        <b>
          Please change it to the {EnvConfig.expected_chain_name} to
          continue
        </b>
      </Alert>
      <Alert
        key="not-on-mint-list"
        show={shouldShowIsNotOnMintListAndMintListIsSetAlert()}
        variant="dark"
        className="sticky-top"
      >
        Thank you for connecting. You are <b>not</b> on the mint list.
        
        <li><b>Public Sale starts at {EnvConfig.public_start_time}!</b>
          </li>
          <li>Potential Mistake? Reach out to us in our <a href="https://discord.gg/s3aVghQfUy" target="_blank" style={{color:"#5865F2"}}>Discord!</a>
            </li>
            <li>
            Wallet address: {toShortedHexString(mintContext.currentAccount)}
              </li>
      </Alert>

      <Alert
        key="alert-success"
        show={shouldShowIsOnMintListButPresaleNotStartedAlert()}
        onClose={() => setShowOnMintListAlert(false)}
        variant="kyw-success"
        className="sticky-top"
        dismissible
      >
        <Alert.Heading>Congratulations! You're on the mint list.</Alert.Heading>
          <li>
          <b>Presale Mint starts at {EnvConfig.presale_start_time} </b>
            </li>
        <li>
        Wallet address: {toShortedHexString(mintContext.currentAccount)}
          </li>
      </Alert>
      <Navbar variant="dark" collapseOnSelect expand="md">
        <Navbar.Brand target="_blank" href="https://founders.knowyourwallet.io/">
          <img
            src={kywLogo}
            alt=""
            width="260"
            height="30"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="text-center">
            <TwitterIcon href="https://twitter.com/knowyourwallet" />
            <DiscordIcon href="https://discord.gg/s3aVghQfUy" />
          </Nav>
          <Nav className="ms-auto">
            <ConnectWallet />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <ToastContainer
        theme="dark"
        position="top-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
      />
      <Container fluid>
        <Row className="pt-4">
          <SaleInfoDisplay
            isPresaleSoldOut={mintContext.isPresaleSoldOut}
            maxSupply={mintContext.maxSupply}
            currentMintCount={mintContext.currentMintCount}
            isPresaleActive={mintContext.isPresaleActive}
            isPublicSaleActive={mintContext.isPublicSaleActive}
            isMetamaskInstalled={mintContext.isMetamaskInstalled}
          />
        </Row>
        <Row>
          <Tile
            title="Founder's"
            updateMintContext={getMintContext}
            image={kywCard}
            descripton={<KYWCardDescription />}
          />
        </Row>
      </Container>
      <footer className="pt-4 text-center">
        <Navbar variant="dark">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="m-auto">
              <DiscordIcon href="https://discord.gg/theincubator" />
              <Navbar.Text style={{ color: "#ffffff" }}>
                Minting Powered by The Incubator
              </Navbar.Text>
              <TwitterIcon href="https://twitter.com/theincubator_" />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </footer>
    </Container>
  );
}

export default App;
