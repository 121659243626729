import MetaMaskOnboarding from "@metamask/onboarding";
import { Button } from "react-bootstrap";

function InstallMetamask() {
  const onClick = () => {
    new MetaMaskOnboarding().startOnboarding();
  };
  return (
    <Button variant="kyw" onClick={onClick}>
      Install MetaMask
    </Button>
  );
}

export default InstallMetamask;
