import { createContext, useState } from "react";
import EnvConfig from "../services/EnvConfig";

const MintContext = createContext({
  chainId: {},
  isMetamaskInstalled: true,
  currentAccount: "",
  mintContext: {},

  isOnMintList: false,
  mintListProof: {},
  isPresaleSoldOut: false,
  isPresaleActive: false,
  isMintListSet: false,
  presalePrice: {},
  maxPresaleMintPerWallet: {},
  presaleMaxSupply: {},
  isPublicSaleActive: false,
  publicPrice: {},
  maxMintPerWallet: {},
  maxSupply: {},
  currentMintCount: {},
  currentUserMintCount: {},

  // note: these aren't needed but are here just to help with autocompletion
  setChainId: (value) => {},
  setIsMetamaskInstalled: (value) => {},
  setCurrentAccount: (value) => {},
  setMintContext: (value) => {},
  
  setIsOnMintList: (value) => {},
  setMintListProof: (value) => {},
  setIsPresaleSoldOut: (value) => {},
  setIsPresaleActive: (value) => {},
  setIsMintListSet: (value) => {},
  setPresalePrice: (value) => {},
  setMaxPresaleMintPerWallet: (value) => {},
  setPresaleMaxSupply: (value) => {},
  setIsPublicSaleActive: (value) => {},
  setPublicPrice: (value) => {},
  setMaxMintPerWallet: (value) => {},
  setMaxSupply: (value) => {},
  setCurrentMintCount: (value) => {},
  setCurrentUserMintCount: (value) => {},
  
  isInvalidChain: (value) => {},
});

export function MintContextProvider(props) {
  const [chainId, setChainId] = useState(
    parseInt(EnvConfig.expected_chain_id)
  );
  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState();
  const [currentAccount, setCurrentAccount] = useState();
  
  
  const [isOnMintList, setIsOnMintList] = useState(null);
  const [mintListProof, setMintListProof] = useState();
  const [isPresaleSoldOut, setIsPresaleSoldOut] = useState();
  const [isPresaleActive, setIsPresaleActive] = useState();
  const [isMintListSet, setIsMintListSet] = useState();
  const [presalePrice, setPresalePrice] = useState();
  const [maxPresaleMintPerWallet, setMaxPresaleMintPerWallet] = useState();
  const [presaleMaxSupply, setPresaleMaxSupply] = useState();
  const [isPublicSaleActive, setIsPublicSaleActive] = useState();
  const [publicPrice, setPublicPrice] = useState();
  const [maxMintPerWallet, setMaxMintPerWallet] = useState();
  const [maxSupply, setMaxSupply] = useState();
  const [currentMintCount, setCurrentMintCount] = useState();
  const [currentUserMintCount, setCurrentUserMintCount] = useState();

  function setChainIdHandler(id) {
    setChainId(id);
  }
  function setIsMetamaskInstalledhandler(state) {
    setIsMetamaskInstalled(state);
  }

  function setCurrentAccountHandler(account) {
    setCurrentAccount(account);
  }

  function setIsOnMintListHandler(state) {
    setIsOnMintList(state);
  }

  function setMintListProofHandler(state) {
    setMintListProof(state);
  }

  function setIsPresaleSoldOutHandler(state) {
    setIsPresaleSoldOut(state);
}

  function setIsPresaleActiveHandler(state) {
      setIsPresaleActive(state);
  }

  function setIsMintListSetHandler(state) {
      setIsMintListSet(state);
  }

  function setPresalePriceHandler(state) {
      setPresalePrice(state);
  }

  function setMaxPresaleMintPerWalletHandler(state) {
      setMaxPresaleMintPerWallet(state);
  }

  function setPresaleMaxSupplyHandler(state) {
      setPresaleMaxSupply(state);
  }

  function setIsPublicSaleActiveHandler(state) {
      setIsPublicSaleActive(state);
  }

  function setPublicPriceHandler(state) {
      setPublicPrice(state);
  }
  
  function setMaxMintPerWalletHandler(state) {
      setMaxMintPerWallet(state);
  }
  function setMaxSupplyHandler(state) {
      setMaxSupply(state);
  }
  function setCurrentMintCountHandler(state) {
      setCurrentMintCount(state);
  }

  function setCurrentUserMintCountHandler(state) {
    setCurrentUserMintCount(state);
}

  function setMintContextHandler(mintContext) {
    setIsOnMintList(mintContext.isOnMintList)
    setMintListProof(mintContext.mintListProof)
    setIsPresaleSoldOut(mintContext.isPresaleSoldOut);
    setIsPresaleActive(mintContext.isPresaleActive);
    setIsMintListSet(mintContext.isMintListSet);
    setPresalePrice(mintContext.presalePrice);
    setMaxPresaleMintPerWallet(mintContext.maxPresaleMintPerWallet);
    setPresaleMaxSupply(mintContext.presaleMaxSupply);
    setIsPublicSaleActive(mintContext.isPublicSaleActive);
    setPublicPrice(mintContext.publicPrice);
    setMaxMintPerWallet(mintContext.maxMintPerWallet);
    setMaxSupply(mintContext.maxSupply);
    setCurrentMintCount(mintContext.currentMintCount); 
    setCurrentUserMintCount(mintContext.currentUserMintCount);
  }

  function isWalletConnectedHandler() {
    return currentAccount !== undefined && currentAccount !== "";
  }

  function isInvalidChainHandler() {
    return chainId !== parseInt(EnvConfig.expected_chain_id);
  }

  const context = {
    chainId: chainId,
    isMetamaskInstalled: isMetamaskInstalled,
    currentAccount: currentAccount,
    isOnMintList: isOnMintList,
    mintListProof: mintListProof,
    isPresaleSoldOut: isPresaleSoldOut,
    isPresaleActive: isPresaleActive,
    isMintListSet: isMintListSet,
    presalePrice: presalePrice,
    maxPresaleMintPerWallet: maxPresaleMintPerWallet,
    presaleMaxSupply: presaleMaxSupply,
    isPublicSaleActive: isPublicSaleActive,
    publicPrice: publicPrice,
    maxMintPerWallet: maxMintPerWallet,
    maxSupply: maxSupply,
    currentMintCount: currentMintCount,
    currentUserMintCount: currentUserMintCount,
    
    setChainId: setChainIdHandler,
    setIsMetamaskInstalled: setIsMetamaskInstalledhandler,
    setCurrentAccount: setCurrentAccountHandler,
    setMintContext: setMintContextHandler,
    
    setIsOnMintList: setIsOnMintListHandler,
    setMintListProof: setMintListProofHandler,
    setIsPresaleSoldOut: setIsPresaleSoldOutHandler,
    setIsPresaleActive: setIsPresaleActiveHandler,
    setIsMintListSet: setIsMintListSetHandler,
    setPresalePrice: setPresalePriceHandler,
    setMaxPresaleMintPerWallet: setMaxPresaleMintPerWalletHandler,
    setPresaleMaxSupply: setPresaleMaxSupplyHandler,
    setIsPublicSaleActive: setIsPublicSaleActiveHandler,
    setPublicPrice: setPublicPriceHandler,
    setMaxMintPerWallet: setMaxMintPerWalletHandler,
    setMaxSupply: setMaxSupplyHandler,
    setCurrentMintCount: setCurrentMintCountHandler,
    setCurrentUserMintCount: setCurrentUserMintCountHandler,

    isInvalidChain: isInvalidChainHandler,
    isWalletConnected: isWalletConnectedHandler,


  };

  return (
    <MintContext.Provider value={context}>
      {props.children}
    </MintContext.Provider>
  );
}

export default MintContext;
