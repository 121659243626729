export const isOnMintListButPresaleNotStarted = (mintContext) => {
  if(mintContext.isPresaleSoldOut === true){
    return false;
  }

  return (
    mintContext.isMintListSet === true && 
    mintContext.isOnMintList === true &&
    mintContext.isPresaleActive === false &&
    mintContext.isPublicSaleActive === false
  );
};

export const isNotOnMintListAndMintListIsSet = (mintContext) => {
  if(mintContext.isPresaleSoldOut === true){
    return false;
  }
  return (
    mintContext.isWalletConnected() &&
    mintContext.isPublicSaleActive === false &&
    mintContext.isPresaleActive === false &&
    mintContext.isOnMintList === false &&
    mintContext.isMintListSet === true
  );
};
