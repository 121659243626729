export const toShortedHexString = (hexstring) => {
  if (!hexstring) {
    return "";
  }

  return [
    hexstring.substring(0, 6),
    "...",
    hexstring.substring(hexstring.length - 4),
  ].join("");
};
