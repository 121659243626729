import { ethers } from "ethers";
// TODO: it would be cool if we could load this from an external source
import mintingAbi from "../abi/KYWFoundersPass.json";
import EnvConfig from "../services/EnvConfig"

const contractAddress = EnvConfig.contract_address;
const merkleEndpoint = EnvConfig.merkle_endpoint;
export class MintingClient {
  getProvider() {
    // TODO: this needs to be config driven
    // return new ethers.providers.JsonRpcProvider();
    return new ethers.providers.Web3Provider(window.ethereum);
  }

  getWriteContract() {
    const provider = this.getProvider();
    const signer = provider.getSigner();
    return new ethers.Contract(contractAddress, mintingAbi, signer);
  }

  getReadOnlyContract() {
    const provider = this.getProvider();
    return new ethers.Contract(contractAddress, mintingAbi, provider);
  }

  async getMintContext(isWalletConnected, connectedAddress) {
    const contract = isWalletConnected
      ? this.getWriteContract()
      : this.getReadOnlyContract();
    let context = await contract.getMintContext();

    let isOnMintList = undefined;
    let mintListProof = null;

    if(isWalletConnected && connectedAddress && 
      !context.isPublicSaleActive && !context.isPresaleSoldOut){
      const presaleData = await this.getPresaleInfo(connectedAddress);
      isOnMintList = presaleData.valid;
      mintListProof = presaleData.proof;
    }
    return {
      isPresaleSoldOut: context.isPresaleSoldOut,
      isOnMintList: isOnMintList,
      mintListProof: mintListProof,
      isPresaleActive: context.isPresaleActive,
      isMintListSet: context.isMintListSet,
      presalePrice: ethers.utils.formatEther(context.presalePrice),
      maxPresaleMintPerWallet: context.maxPresaleMintPerWallet.toNumber(),
      presaleMaxSupply: context.presaleMaxSupply.toNumber(),
      isPublicSaleActive: context.isPublicSaleActive,
      publicPrice: ethers.utils.formatEther(context.publicPrice),
      maxMintPerWallet: context.maxMintPerWallet.toNumber(),
      maxSupply: context.maxSupply.toNumber(),
      currentMintCount: context.currentMintCount.toNumber(),
      currentUserMintCount: context.currentUserMintCount,
    };
  }


  async getPresaleInfo(connectedAddress){
    const endpoint = `${merkleEndpoint}${connectedAddress}`
    const response = await fetch(endpoint);
    const data = await response.json();
    return data;
  }


  async mintPublic(amountToMint, price) {
    console.log(
      `Minting public with amountToMint ${amountToMint} | ${price}`
    );
    const contract = this.getWriteContract();
    const amount = ethers.utils
      .parseUnits(price, "ether")
      .mul(amountToMint);
    return await contract.mint(amountToMint, {
      value: amount,
    });
  }


  async mintPresale(proof, amountToMint, price) {
    console.log(
      `Minting presale with amountToMint ${amountToMint} | ${price}`
    );
    const contract = this.getWriteContract();
    const amount = ethers.utils
      .parseUnits(price, "ether")
      .mul(amountToMint);
    return await contract.mintPresale(proof, amountToMint, {
      value: amount,
    });
  }
}
