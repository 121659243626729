import React from "react";
import discordLogo from "../images/discord-logo.png";
import { Nav, Image } from "react-bootstrap";
function DiscordIcon(props) {
  return (
    <div>
      <Nav.Link href={props.href} target="_blank">
        <Image width="30" height="30" src={discordLogo} />
      </Nav.Link>
    </div>
  );
}

export default DiscordIcon;
