import React from "react";
import { toShortedHexString } from "../utils/Utils";
import InstallMetamask from "./InstallMetamask";
import { Button } from "react-bootstrap";
import MintContext from "../store/mint-context";
import { useContext, useEffect } from "react";
function ConnectWallet() {
  const mintContext = useContext(MintContext);

  useEffect(() => {
    if (mintContext.isMetamaskInstalled) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }

    return () => {
      if (mintContext.isMetamaskInstalled) {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
      }
    };
  }, []);

  const connectWallet = async () => {
    try {
      const { ethereum } = window;
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      handleAccountsChanged(accounts);
    } catch (error) {
      console.log(error);
    }
  };

  function handleAccountsChanged(accounts) {
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      mintContext.setCurrentAccount("");
    } else if (accounts[0] !== mintContext.currentAccount) {
      mintContext.setCurrentAccount(accounts[0]);
    }
  }

  if (!mintContext.isMetamaskInstalled) {
    return <InstallMetamask />;
  } else {
    return (
      <Button
        variant="kyw"
        onClick={connectWallet}
        disabled={
          mintContext.isWalletConnected() || mintContext.isInvalidChain()
        }
      >
        {mintContext.isWalletConnected()
          ? `${toShortedHexString(mintContext.currentAccount)}`
          : "Connect Wallet"}
      </Button>
    );
  }
}

export default ConnectWallet;
