import React from "react";
import EnvConfig from "../services/EnvConfig";

function SaleInfoDisplay(props) {
  
  const isSoldOut = props?.maxSupply - props?.currentMintCount <= 0
  let preSaleInfo = `Presale starts at ${EnvConfig.presale_start_time}`;
  let publicSaleInfo = `Public sale starts at ${EnvConfig.public_start_time}`;

  if(isSoldOut){
    return ""
  }

  if (!props.isMetamaskInstalled) {
    return <b style={styles.saleDetailText}>Please install metamask</b>;
  }

  if(props?.isPresaleSoldOut && !props?.isPublicSaleActive){
    return (
      <div style={styles.saleDetailText}>
        <b>{publicSaleInfo}</b>
      </div>
    )
  }

  if (props.isPresaleActive) {
    preSaleInfo = `Presale has started!`;
  }

  if (props.isPublicSaleActive) {
    return (
      <div style={styles.saleDetailText}>
        <b>Public sale is active!</b>
      </div>
    );
  }
  return (
    <div>
      <div style={styles.saleDetailText}>
        <b>{preSaleInfo}</b>
      </div>
      <div style={styles.saleDetailText}>
        <b>{publicSaleInfo}</b>
      </div>
    </div>
    
  );
}

const styles = {

  saleDetailText:{
    justifyContent: "center",
    display: "flex",
    marginBottom: "20px"
  }

}


export default SaleInfoDisplay;
