import React from "react";
import MintContext from "../store/mint-context";
import { toShortedHexString } from "../utils/Utils";
import { useContext, useRef } from "react";
import { Button, Row, Col, Card, Form } from "react-bootstrap";
import ConnectWallet from "./ConnectWallet";
import { toast } from "react-toastify";
import { MintingClient } from "../clients/MintingClient";
import EnvConfig from "../services/EnvConfig";

function Tile(props) {
  const mintContext = useContext(MintContext);
  const amountToMintRef = useRef();
  const mintingClient = new MintingClient();

  function determinePrice(){
    if(mintContext.isPresaleActive){
      return mintContext.presalePrice;
    } else {
      return mintContext.publicPrice;
    }
  }

  function getRemainingSupply(maxSupply, currentMintCount) {
    if (maxSupply === undefined || currentMintCount === undefined) {
      return "-";
    }
    return maxSupply - currentMintCount;
  }

  const range = (start, end) =>
    Array.from({ length: end }, (v, k) => k + start);


  function calculateRemaingingMints(maxMintPerWallet, currentUserMintCount){
    const remaining = maxMintPerWallet - currentUserMintCount;
    // remaining can be zero if we reserve some one more NFTs that presale max per wallet or public sale max per wallet
    return remaining < 0 ? 0 : remaining;
  }

  function renderForm(
    isSoldOut,
    isInvalidChain,
    isPublicSaleActive,
    isPresaleActive,
    isOnMintList,
    price,
    currentUserMintCount,
    maxPresaleMintPerWallet,
    maxMintPerWallet
  ) {
    if (isSoldOut) {
      return "";
    }

    if (isInvalidChain) {
      return <ConnectWallet />;
    }
    let button = <ConnectWallet />;

    if (isPublicSaleActive) {
      const remainingMintCount = calculateRemaingingMints(maxMintPerWallet, currentUserMintCount);
      if (remainingMintCount === 0) {
        return `You’ve bought all your ${props.title} passes!`;
      }
      if (mintContext.isWalletConnected()) {
        button = (
          <Button
            onClick={() => mint("public", price)}
            variant="kyw"
            type="submit"
          >
            Mint
          </Button>
        );
      }
      const mintInfo = (
        <p>
          Limit {maxMintPerWallet} per wallet. You have{" "}
          {remainingMintCount} available to mint
        </p>
      );
      return (
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row>
            <Col>
              <Form.Select 
              disabled={
                !mintContext.isWalletConnected()
              }
              ref={amountToMintRef}>
                {range(1, remainingMintCount).map((n) => (
                  <option key={n} value={n}>
                    {n}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col>{button}</Col>
          </Row>
          <Row className="pt-2">{mintInfo}</Row>
        </Form>
      );
    }

    if (isPresaleActive) {
      let mintListInfo;
      
      const remainingPresaleMintCount = calculateRemaingingMints(maxPresaleMintPerWallet, currentUserMintCount);
      if (remainingPresaleMintCount === 0 && isOnMintList) {
        return `You’ve bought all your ${props.title} presale passes, come back during the public sale to mint more!`;
      }

      if (
        mintContext.isWalletConnected() &&
        isOnMintList &&
        remainingPresaleMintCount !== 0
      ) {
        button = (
          <Button
            onClick={() => mint("presale", price)}
            variant="kyw"
            type="submit"
          >
            Presale Mint
          </Button>
        );
        mintListInfo = (
          <p>
            Limit {mintContext.maxPresaleMintPerWallet} per wallet for presale. You have{" "}
            {remainingPresaleMintCount} available to mint
          </p>
        );
      } else if (mintContext.isWalletConnected() && !isOnMintList) {
        button = (
          <Button
            onClick={() => mint("presale", price)}
            variant="kyw"
            type="submit"
            disabled
          >
            Not on mint list
          </Button>
        );
      }

      return (
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row>
            <Col>
              <Form.Select
                ref={amountToMintRef}
                disabled={
                  !isOnMintList || remainingPresaleMintCount === 0
                }
              >
                {range(1, remainingPresaleMintCount).map((n) => (
                  <option key={n} value={n}>
                    {n}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col>{button}</Col>
          </Row>

          <Row className="pt-2">{mintListInfo}</Row>
        </Form>
      );
    }

    if (!isPublicSaleActive && !mintContext.isWalletConnected()) {
      return <ConnectWallet />;
    }
  }

  function isSoldOut(maxSupply, currentMintCount) {
    // this can happen if the user is connected to the wrong chain
    if (maxSupply === undefined || currentMintCount === undefined) {
      return false;
    }
    return maxSupply === currentMintCount;
  }

  function getMintCall(mintType) {
    const map = {
      public: function mint(amountToMint, pricePerToken) {
          return mintingClient.mintPublic(amountToMint, pricePerToken);
      },
      presale: function mint(amountToMint, pricePerToken) {
          return mintingClient.mintPresale(mintContext.mintListProof, amountToMint, pricePerToken);
      }
    };

    return map[mintType.toLowerCase()];
  }

  const mint = async (mintType, price) => {
    const amountToMint = amountToMintRef.current.value;
    console.log("Amount to mint: ", amountToMint);
    try {
      const mint = getMintCall(mintType);

      const nftTxn = await mint(amountToMint, price);
      const response = await toast.promise(nftTxn.wait(), {
        pending: " Minting NFT",
        success: {
          render({ data }) {
            const etherScanurl =
            EnvConfig.ether_scan_link + data.transactionHash;

            return (
              <p>
                Successfully Minted!!
                <br />
                <a
                  href={etherScanurl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View transaction
                </a>
              </p>
            );
          },
        },
        error: {
          render({ error }) {
            return `Error occured while minting: ${getErrorMessage(error)}`;
          },
        },
      });

      props.updateMintContext();
    } catch (error) {
      console.log(`An error occurred ${error}`);
      // error code 4001 is that the user canceled the transaction
      if (error.code !== 4001) {
        toast.error(`An error occurred ${getErrorMessage(error)}`, {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    }
  };

  function getErrorMessage(error) {
    if (error?.error?.message !== undefined) {
      return error.error.message;
    }
    
    if (error?.error?.data?.message !== undefined) {
      return error.error.data.message;
    }

    if (error?.data?.message !== undefined) {
      return error.data.message;
    }

    if (error?.code !== undefined) {
      return error.code;
    }

    if (error?.reason !== undefined) {
      return error.reason;
    }

    if (error?.message !== undefined) {
      return error.message;
    }

    return "An unknown error occured";
  }

  const soldout = isSoldOut(mintContext.maxSupply, mintContext.currentMintCount);

  function getTitle(soldout) {
    if (soldout) {
      return (
        <div>
          <p style={{ color: "red" }}>SOLDOUT </p>
          <s>{props.title.toUpperCase()} PASS</s>
        </div>
      );
    } else {
      return <h1 style={ {color: "#f2cb2e", fontWeight: "bold"} }>THE {props.title.toUpperCase()} PASS</h1>;
    }
  }

  function renderConnectedAs() {
    if (mintContext.isWalletConnected()) {
      return (
        <div className="d-block d-md-none">
          Connected as {toShortedHexString(mintContext.currentAccount)}
        </div>
      );
    }
  }

  const price = determinePrice();
  return (
    <div>
    <div
        className="fusion-column-wrapper 
        fusion-column-has-shadow 
        fusion-flex-justify-content-flex-start 
        fusion-content-layout-column
        tile
        "
      >
<Row>
    <Col style={styles.imageContainer}>      
        <div>
          <span className="fusion-imageframe imageframe-none imageframe-5 hover-type-none">
            <img
              width="412"
              height="246"
              title={props.title}
              src={props.image}
              alt=""
              style={styles.imageDiv}
              className="img-responsive wp-image-2752 lazyloaded"
            />
          </span>
        </div>
        </Col>
    <Col sm={6} style={styles.cardContainer}>
    <div
          className="fusion-text fusion-text-2 fusion-text-no-margin"
          style={{ marginBottom: "20px"}}
        >
          
          <h4
            data-fontsize="24"
            data-lineheight="19.2px"
            className="fusion-responsive-typography-calculated"
            style={{
              "--fontSize": "24",
              lineHeight: "0.8",
              "--minFontSize": "24",
            }}
          >
            <em>
              <strong>{getTitle(soldout)}</strong>
            </em>
          </h4>
          <h4
            data-fontsize="24"
            data-lineheight="19.2px"
            className="fusion-responsive-typography-calculated"
            style={{
              "--fontSize": "24",
              lineHeight: "0.8",
              "--minFontSize": "24",
            }}
          >
            <em>
              {getRemainingSupply(mintContext.maxSupply, mintContext.currentMintCount)}
              {" out of "}
              {mintContext.maxSupply} left
            </em>
          </h4>
        </div>
        {props.descripton}
        {price !== 0 && (
          <Card.Title>{price} ETH Each</Card.Title>
        )}

        {renderForm(
          soldout,
          mintContext.isInvalidChain(),
          mintContext.isPublicSaleActive,
          mintContext.isPresaleActive,
          mintContext.isOnMintList,
          price,
          mintContext.currentUserMintCount,
          mintContext.maxPresaleMintPerWallet,
          mintContext.maxMintPerWallet
        )}

        {renderConnectedAs()}
    

    </Col>
  </Row>  
  </div>
  </div>
  );
}

const styles = {
  imageContainer:{
    background:"#222b36",
    borderRadius: "30px 30px 30px 30px",
    borderWidth: "4px 4px 4px 4px",
    borderColor: "#f2cb2e",
    margin: "auto",
    padding:"30px",
    marginRight: "10px",
    marginLeft: "10px",
    marginTop: "10px"
  },
  imageDiv: {
    borderRadius: "30px 30px 30px 30px",
  },

  cardContainer:{
    background:"#222b36",
    borderRadius: "30px 30px 30px 30px",
    borderWidth: "4px 4px 4px 4px",
    borderColor: "#f2cb2e",
    // marginRight: "10px",
    marginLeft: "10px",
    marginTop: "10px",
    paddingBottom: "10px",
    paddingLeft:"30px",
    paddingRight:"30px",
  }
  
}


export default Tile;
