import React from "react";
import twitterLogo from "../images/twitter-logo.png";
import { Nav, Image } from "react-bootstrap";
function TwitterIcon(props) {
  return (
    <div>
      <Nav.Link href={props.href} target="_blank">
        <Image width="30" height="30" src={twitterLogo} />
      </Nav.Link>
    </div>
  );
}

export default TwitterIcon;
